.wrapper {
  position: fixed;
  inset: var(--finder-collapsed-header-height) 0 var(--finder-mobile-bottom-touchpoint) 0;
  background: white;
}

.mapHeight {
  height: 100%;

  @apply relative;
}

@media screen and (min-width: 768px) {
  .wrapper {
    left: var(--finder-desktop-listing-width);
    bottom: 0;
    top: var(--header-height);
  }
}
