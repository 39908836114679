.listingWrapper {
  -webkit-tap-highlight-color: transparent;
  
  @apply fixed h-full bg-white inset-0 z-10;
}

.fullHeightListing {
  transform: translateY(var(--finder-mobile-top-touchpoint)) translateZ(0);
}

.halfHeightListing {
  transform: translateY(55%) translateZ(0);
}

.lowHeightListing {
  transform: translateY(calc(100% - var(--finder-mobile-bottom-touchpoint)))
    translateZ(0);
}

.listingWrapperHandle {
  padding-top: 10px;
  padding-bottom: 20px;

  @apply md:hidden flex justify-center items-center mb-xs;
}

.listingWrapperHandleSpan {
  width: 64px;
  height: 6px;

  @apply bg-light-grey rounded-full;
}

.mobileListingTransition {
  transition: transform 250ms ease-out;
}

.hideButton {
  transform: translateY(54px) translateZ(0);
}

.showButton {
  transform: translateY(0) translateZ(0);
}

@media screen and (min-width: 768px) {
  .listingWrapper {
    top: var(--header-height);
    width: var(--finder-desktop-listing-width);
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  .listingWrapper {
    @apply w-full;
  }
}
